




























































































import MNotificationVue from "@/mixins/MNotification.vue";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { FormValue } from "@/store/stockAdj.store";
import {
  useCountQtyDifference,
  useStockAdjustment,
} from "@/views/logistic/stock-adjustment/hooks";
import { StockAdjustmentResponseDTO } from "@/views/logistic/stock-adjustment/types";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

@Component({
  beforeRouteLeave(to, from, next) {
    this.resetStore();
    next();
  },
  computed: {
    ...mapGetters({
      form: "stockAdjStore/getForm",
      detail: "stockAdjStore/getDetail",
      isOpname: "stockAdjStore/isOpname",
    }),
  },
  methods: {
    ...mapActions({
      resetStore: "stockAdjStore/resetStore",
    }),
  },
})
export default class DetailPage extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  useCountQtyDifference = useCountQtyDifference;

  form!: FormValue;
  detail!: StockAdjustmentResponseDTO;
  isOpname!: boolean;
  resetStore!: () => void;

  columnAdj = [
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      width: "20%",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      width: "20%",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "productMerk",
      width: "20%",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty_adjustment"),
      dataIndex: "qty",
      width: "20%",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uomName",
      width: "20%",
      scopedSlots: { customRender: "nullable" },
    },
  ];

  columnOpn = [
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "productMerk",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty_total"),
      dataIndex: "qty",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uomName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty_physical"),
      dataIndex: "physicalQty",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_qty_difference"),
      dataIndex: "differenceQty",
      scopedSlots: { customRender: "differenceQty" },
    },
  ];

  get columns() {
    return this.isOpname ? this.columnOpn : this.columnAdj;
  }

  handleBack(): void {
    this.$router.push({
      name: "logistic.stock-adjustment",
    });
  }

  showAttachment(): void {
    const { viewAttachment } = useStockAdjustment();
    viewAttachment(this.form.attachment);
  }
}
